<template>
  <div style="max-width: 100% !important;">
    <iframe
      src="https://greenn.com.br/movimento-dsa"
      width="100%"
      height="100%"
      frameborder="0"
      style="border: 0;"
      allowfullscreen>
    </iframe>
  </div>
</template>

<script>
export default {
  name: "page-dsa",
  props: {
    no_product: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toRouter(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style scoped>
p {
  color: var(--gray-200);
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  margin: 0;
}
h6 {
  font-weight: 600;
}
.box-content {
  margin-top: 40px;
  background: var(--gray-white);
  background: var(--gray-10);
  border-radius: 10px;
  padding: 40px;
  padding-right: 80px;
  max-width: 968px;
}
</style>
